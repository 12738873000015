import {
  performanceAnalyticsData,
  scrollDepth,
  timeOnPage,
  linkTracking,
  sendAPIResults,
  readAnalytics,
  elementImpression,
  currentTemplate,
  currentVersion,
  screenHeight,
  screenWidth,
  gTag,
  fingerprint
} from 'operation-metrics-agent'
;(() => {
  if (window.OP_METRICS != null) {
    Object.defineProperty(window.OP_METRICS, 'referrer', {
      value: document.referrer,
      writable: false
    })
    Object.defineProperty(window.OP_METRICS, 'site_version', {
      value: currentVersion(),
      writable: false
    })
    Object.defineProperty(window.OP_METRICS, 'template', {
      value: currentTemplate(),
      writable: false
    })
    Object.defineProperty(window.OP_METRICS, 'screen_height', {
      value: screenHeight(),
      writable: false
    })
    Object.defineProperty(window.OP_METRICS, 'screen_width', {
      value: screenWidth(),
      writable: false
    })
    readAnalytics()
    gTag()
  }
})()

const OMConfig = window.metricsConfig

if (OMConfig.performance) {
  const afterLoad = (): void => {
    if (document.readyState === 'complete') {
      // Queue a task so the callback runs after `loadEventEnd`.
      setTimeout(performanceAnalyticsData, 0)
    } else {
      // Use `pageshow` so the callback runs after `loadEventEnd`.
      addEventListener('pageshow', performanceAnalyticsData)
    }
  }

  afterLoad()
}

const engagement = (): void => {
  if (OMConfig.fingerprint.hash) {
    fingerprint()
    .catch(err => console.log(err))
  }
  if (OMConfig.scroll_depth) {
    scrollDepth()
  }
  if (OMConfig.time_on_page) {
    timeOnPage()
  }
  if (
    OMConfig.element_impression?.name != null && OMConfig.element_impression?.type != null
  ) {
    const namedTargets = OMConfig.element_impression.name
    const typeTargets = OMConfig.element_impression.type

    let selector = namedTargets
    if (typeTargets === 'data') {
      selector = '[data-' + selector + ']'
    } else {
      selector = '#' + selector
    }

    const targetsToObserveNodeList: NodeListOf<HTMLElement> = document.querySelectorAll(
        // @ts-expect-error
        selector
      )

    let targetsToObserve: HTMLElement[] = Array.prototype.slice.call(
      targetsToObserveNodeList
    )

    targetsToObserve = typeTargets === 'data' ? targetsToObserve : targetsToObserve.slice(0, 1)

    targetsToObserve.forEach((target: HTMLElement) => {
      elementImpression(target, OMConfig.element_impression)
    })
  }
  if (OMConfig.api_test) {
    sendAPIResults()
  }
  if (OMConfig.click_triggers) {
    linkTracking()
  }
}

document.addEventListener('DOMContentLoaded', engagement, false)
